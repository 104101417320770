import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				AceTennis – Забронируйте свой корт сегодня
			</title>
			<meta name={"description"} content={"Откройте для себя аренду теннисных кортов высшего качества в AceTennis. Наши ухоженные корты идеально подходят для матчей, тренировок и дружеских игр. Забронируйте сейчас, чтобы получить превосходный теннисный опыт!"} />
			<meta property={"og:title"} content={"AceTennis – Забронируйте свой корт сегодня"} />
			<meta property={"og:description"} content={"Откройте для себя аренду теннисных кортов высшего качества в AceTennis. Наши ухоженные корты идеально подходят для матчей, тренировок и дружеских игр. Забронируйте сейчас, чтобы получить превосходный теннисный опыт!"} />
			<link rel={"shortcut icon"} href={"https://glowesbair.org/img/5738682.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glowesbair.org/img/5738682.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glowesbair.org/img/5738682.png"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="100vh"
			background="url(https://images.unsplash.com/photo-1610337673044-720471f83677?auto=format&fit=crop&w=1500&q=80) center center/cover no-repeat"
			md-background="url(https://images.unsplash.com/photo-1610337673044-720471f83677?auto=format&fit=crop&w=800&q=80) center center/cover no-repeat"
			padding="88px 0 88px 0"
			quarkly-title="404-7"
		>
			<Override slot="SectionContent" justify-content="center" max-width="1220px" align-items="center" />
			<Box
				border-radius="24px"
				display="flex"
				justify-content="center"
				padding="56px 106px 64px 106px"
				background="--color-dark"
				max-width="600px"
				sm-padding="26px 30px 34px 30px"
				align-items="center"
				flex-direction="column"
			>
				<Text font="--headline1" color="--light" margin="0px 0px 16px 0px" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					404
				</Text>
				<Text
					md-width="100%"
					font="--lead"
					color="--lightD1"
					margin="0px 0px 40px 0px"
					text-align="center"
					width="100%"
					lg-width="80%"
				>
					The page you are looking for is not available or doesn’t belong to this website!
				</Text>
				<Link
					font="--lead"
					padding="12px 24px 12px 24px"
					border-radius="8px"
					background="--color-light"
					href="/index"
					text-decoration-line="initial"
					color="--dark"
				>
					Go Back to Home
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});